@import url('https://fonts.googleapis.com/css2?family=Anaheim&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&family=Train+One&display=swap');

.csrevision {
    color: white;
    font-size: 1.3rem;
    text-decoration: none;
    font-family: Segoe UI, sans-serif;
}

body {
    margin: 0;
    font-family: 'Anaheim', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #0e0b16;
    color: #e0e0e0;
}

header {
    padding: 1rem 2rem;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav ul {
    list-style: none;
    display: flex;
    gap: 1.5rem;
}

nav ul li {
    display: inline;
}

nav ul li a {
    color: #e0e0e0;
    text-decoration: none;
    font-weight: bold;
}

@keyframes backgroundTransition {
    0% {
        background: #111133;
    }
    20% {
        background: #113333;
    }
    40% {
        background: #113311;
    }
    60% {
        background: #331111;
    }
    80% {
        background: #333311;
    }
    100% {
        background: #111133;
    }

}

/** COOL screen GIF: background: url("https://i.gifer.com/EHil.gif"); */
/** cube gif: https://giphy.com/embed/9ZsHm0z5QwSYpV7g01https://i.gifer.com/GYny.gif"); */
#hero {
    background-size: 100% 100%;
    background: #000022 no-repeat fixed;
    animation-name: backgroundTransition;
    animation-duration: 60s;
    animation-iteration-count: infinite;
    display: flex;
    flex-direction: row;
    align-items: center;

}


.hero-container {
    margin: 0 5rem;
}


#profileTitle {
    display: block;
}

.hero-container h1 {
    display: block;
    color: #00dda5;
    font-family: 'Titillium Web', sans-serif;
    margin-bottom: -1rem;
    font-size: 4rem;
}

.hero-container h2 {
    font-size: 1.5rem;
    color: #00ff85;

}

.content-section {

    text-align: center;
}

.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.profile-img {
    height: 25rem;

}

#hero-wrapper {
    display: flex;
    flex-direction: row;
}

#projects {
    margin-top: 0;
}

#projectsHeader {
    color: white;
    font-size: 3rem;
}

.projects-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    margin-bottom: 10rem;
}

.project:hover {
    animation-name:projectHoverIn;
    animation-duration: 0.3s;
    animation-fill-mode: both;
}

.project {
    animation-name:projectHoverOut;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    padding: 1rem;
    width: 40%;
    text-align: left;
    height: 45rem;
    border-radius: 1rem;
    text-decoration:none;
}

@keyframes projectHoverIn {
    0% {background-color: #1f1f3d;}
    100% {background-color: #1f3f3d;}
}

@keyframes projectHoverOut {
    0% {background-color: #1f2f3d;}
    100% {background-color: #1f1f3d;}
}



.project img {
    max-width: 100%;
    max-height: 70%;
    border-radius: 0.3rem;
    display: block;
    margin: auto;
}

a {
    color: white;
    text-underline-offset: 1vh;
}

.project h3 {
    font-size: 1.5rem;
    color: #00e5ff;
    margin: 0.5rem 0;
}

.project p {
    font-size: 1rem;
    color: #b3b3b3;
}

footer {
    background-color: #1f1f3d;
    padding: 1rem 2rem;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.footer-container p {
    font-size: 0.875rem;
    color: #b3b3b3;
}

.profile-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.profile-img {
    height: 25rem;
}


.profile-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    padding-left:6rem;


}

.profile-wrapper h2 {
    font-size: 2.5rem;
}

.hero-container p {
    font-size: 1.5rem;
    font-family: 'Titillium Web', sans-serif;
}

.hero-container h3 {
    font-size: 2rem;
    font-family: 'Titillium Web', sans-serif;
    margin-top: 5rem;
}

.mobile-profile-img {
    display: none;
}


.project h3 {
    padding-top: 1rem;
    font-size: 2rem;
}

.project p {
    font-size: 1.4rem;
    color: #dddddd;
    font-family: 'Titillium Web', sans-serif;
}

/* ====== */

.blockmaster-backlink {
    padding:1rem;
    font-family: 'Titillium Web', sans-serif;
    font-size: 1rem;
    text-align: center;
    background-color: #0e0b26;

}

.blockmaster-backlink a {
    text-decoration: none;

}

.blockmaster1-img-mobile {
    display: none;
}

.blockmaster1-img {
    height: 50rem;
}

.blockmaster-hero-container {
    margin: 0;
    padding: 0;
}


.blockmaster-hero-container h1 {
    color: #00ff85;
    font-family: 'Titillium Web', sans-serif;
    margin-bottom: -1rem;
    font-size: 4.5vw;
}

.blockmaster-hero-container h2 {
    font-size: 3rem;
    font-family: 'Anaheim', sans-serif;
    color: #eeeeee;

}


.blockmaster-intro p {
    font-size: 2rem;
    font-family: 'Titillium Web', sans-serif;
}

#blockmaster-hook-p {
    font-weight: bold;
    font-family: 'Titillium Web', sans-serif;
    font-size: 2.2rem;
}

.blockmaster-hero-container-h3 {
    font-size: 3rem;
    font-family: 'Titillium Web', sans-serif;

}

#blockmaster-profile-text-wrapper {
    display: flex;
    flex-direction: row;
    #justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-left: 4rem;

}

.blockmaster-intro {
    padding: 5rem 6rem;
    background: black;


}

.blockmaster-profile-wrapper h2 {
    font-size: 2.5rem;
}

.blockmaster-profile-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    #justify-content: space-evenly;
    background: black;
}

.blockmaster-head-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.blockmaster-img-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.blockmaster-img-wrapper img {
    width: 30rem;
    height: 30rem;
}

.blockmaster-mobile-only {
    display: none;
}

#blockmaster-features-root {
    display: flex;
    flex-direction: column;
    #justify-content: space-evenly;
    flex-wrap: wrap;

}

::-webkit-scrollbar {
    display: none;
}

.blockmaster-feature {
    height: auto;
    display: flex;
    padding: 4rem 6rem;
    align-items: center;
    #justify-content: space-around;

}

.blockmaster-feature:nth-child(1) {
    background-color: #440000;
}

.blockmaster-feature:nth-child(2) {
    background-color: #334400;
}

.blockmaster-feature:nth-child(3) {
    background-color: #003344;
}

.blockmaster-feature:nth-child(odd) {
    flex-direction: row;
}

.blockmaster-feature:nth-child(even) {
    flex-direction: row-reverse;
}


.blockmaster-feature-inner-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 4rem;
}

.blockmaster-feature h1 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 3rem;
    margin: unset;
    color: white;

}

.blockmaster-feature img {
    height: 25rem;
}

.blockmaster-feature p {
    font-family: 'Titillium Web', sans-serif;
    font-size: 2rem;
    text-align: left;
    line-height: 1.7;
    color: white;

}

.blockmaster-description-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    color: black;
    padding: 5rem 4rem 6rem;


}

.blockmaster-description-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 4rem;

}

.blockmaster-description-wrapper img {
    width: 30%;

}

.blockmaster-description-wrapper h3 {
    font-size: 3rem;

}

.blockmaster-description-text-wrapper p {
    font-size: 2rem;
    font-family: 'Titillium Web', sans-serif;
}


.blockmaster-updates-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 4rem;

}

.blockmaster-update-block-title h3 {
    font-size: 3rem;
}

.blockmaster-updates-text-wrapper h2 {
    color: #00ff85;
    font-family: 'Titillium Web', sans-serif;
    margin: 1rem 0;
}

.blockmaster-updates-text-wrapper-h3 {
    color:#00eeff;
    font-family: 'Anaheim', sans-serif;
    margin: 0;
    font-size: 2.5rem;

}

.blockmaster-updates-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #111111;
    color: black;
    padding: 2rem 4rem;
    gap: 1rem;
}

.blockmaster-update-block-title {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: white;
    gap: 3rem;

}


.blockmaster-update-block {
    display: flex;
    flex-direction: column;
    background: #222222;
    padding: 0.4rem 2rem;
    margin: 2rem 0;
    border-radius: 2rem;

}

.blockmaster-update-block-pinned {
    display: flex;
    flex-direction: column;
    background: #223122;
    padding: 0.4rem 2rem;
    margin: 2rem 0;
    border-radius: 2rem;

}


.blockmaster-update-block h3 {
    font-family: 'Titillium Web', sans-serif;
    color: #eeeeee;
    margin: 1.2rem 1.2rem 0;

}

.blockmaster-update-block-pinned h3 {
    font-family: 'Titillium Web', sans-serif;
    color: #eeeeee;
    margin: 1.2rem 1.2rem 0;

}

.blockmaster-update-block-title {
    padding-top: 1rem;
    padding-bottom: 0;
}

.blockmaster-update-block-title-stamp {
    color: #aaaaaa;
    font-size: 1.3rem;
    padding: 0;
    margin-bottom: 0;
    font-family: 'Titillium Web', sans-serif;
}

.blockmaster-update-image {

    width: 40%;
    margin: 2rem 1rem;
    border: 2px solid black;
}

.blockmaster-update-block-contents p {
    font-size: 1.4rem;
    line-height: 1.7;
    color: #eeeeee;
    font-family: 'Segoe UI', sans-serif;
}


.blockmaster-updates-thread-divider {
    height: 0.1rem;
    background: black;
}

.blockmaster-updates-wrapper-image {
    width: 40%;
}

.blockmaster-update-block-title-img {
    width: 5%;
    border-radius: 100%;
}

.blockmaster-update-block-contents-example-prompt {
    font-family: 'Calibri', sans-serif;
    padding: 2rem 0;
    font-size: 1.5rem;
    color: white;
    border-radius: .5rem .5rem 0 0;
}

.blockmaster-update-block-contents-example-tuned-and-untuned-wrapper {
    display: flex;
    flex-direction: row;
    line-height: 1.5;
    margin-bottom: 0

}

.blockmaster-update-block-contents-example-untuned {
    background-color: #333366;
    padding: 0.5rem 1.5rem 2rem 1.5rem;
    color: #dddddd;
    font-size: 1.2rem;
    font-family: 'Titillium Web', sans-serif;
}

.blockmaster-update-block-contents-example-tuned {
    background-color: #336633;
    padding: 0.5rem 1.5rem 2rem 1.5rem;
    color: #dddddd;
    font-size: 1.2rem;
    font-family: 'Titillium Web', sans-serif;
}

.blockmaster-update-block-contents-example-tuned-and-untuned-wrapper h3 {
    margin: 0.5rem 0 0 0;

}

.blockmaster-update-block-contents-example-verdict p {
    margin: 0;
    font-size: 1.3rem;
    line-height: 2;


}

.blockmaster-update-block-contents-example-verdict {
    padding: 1rem;
    margin: 0;
    background: black;
}

.blockmaster-developer-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #000033;
    color: white;
    padding: 5rem 4rem 6rem;


}

.blockmaster-hf-demo-link {
    color: black;
}

.blockmaster-developer-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 4rem;

}

.blockmaster-developer-text-wrapper p {
    font-size: 2rem;
    font-family: 'Titillium Web', sans-serif;

}

.blockmaster-developer-wrapper img {
    width: 30%;
    border-radius: 4rem;


}

.blockmaster-developer-wrapper h3 {
    font-size: 3rem;

}


#blockmaster-footer {
    padding: 1rem;
    height: 1.3rem;
    display: flex;
    font-size: 1.4rem;
    flex-direction: row;
    font-family: 'Segoe UI', sans-serif;
    justify-content: space-around
}

/* ====== */

.project-description-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 3rem;
}


.project-description-container h1 {
    display: block;
    color: #00dda5;
    font-family: 'Titillium Web', sans-serif;
    font-size: 3rem;
    margin:0;
}

.project-description-container h2 {
    font-size: 2.5rem;
    color: #00ff85;

}

.project-description-container .title-wrapper {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

}

.project-description-container .title-wrapper img {
    border-radius:100%;
    height:5rem;

}

.project-banner-wrapper {
    margin:0;
    height:40%;
}

.project-banner-wrapper img {
    width:100%;
    height:100%;
    object-fit:cover;
}

.project-description-container p {
    font-family:"Titillium Web",sans-serif;
    font-size: 1.5rem;
    color: #ffffff;
    line-height:3rem;

}

.project-description-container .content-wrapper img {
    width:50%;
    border-radius:1rem;
}


@media screen and (max-width: 768px) {

    .profile-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .profile-text-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 2rem;
        text-align: center;
        align-items: center;
        padding-left: 0;
    }

    #hero {
        flex-direction: column;
        align-items: flex-end;
        padding: 0.2rem 0;

    }

    .profile-img {
        display: none;
    }


    .mobile-profile-img {
        display: unset;
        height: 20rem;
        margin-bottom: 3rem;
    }


    .hero-container {
        margin: 0 0;
        text-align: center;
    }

    .hero-container h1 {
        font-size: 4rem;
        line-height: 1.2;
        #justify-content: center;
        margin-bottom: 1.5rem;

    }

    .hero-container h2 {
        font-size: 2.3rem;
        text-align: center;
        margin: 0;


    }

    .hero-container p {
        font-size: 1.3rem;
        margin: 1.5rem;
    }

    footer {
        background: black;
        width: 100%;
        left: -5vw;
    }

    .project {
        width: 80%;
    }

    /* ====== */


    #blockmaster-hero {
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    .blockmaster-hero-container {
        text-align: center;
    }

    #blockmaster-profile-text-wrapper {
        margin: 0;
    }

    #blockmaster-profile-text-wrapper h1 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 3.5rem;
        line-height: 1.5;
        margin: 1rem 1.7rem;
        display: flex;
    }

    .blockmaster1-img {
        display: none;
    }

    .blockmaster1-img-mobile {
        display: unset;
        width: 100%;
        margin: 0;
        height: auto;


    }

    .blockmaster-intro {
        text-align: left;
        padding: 2rem 2rem;


    }

    .blockmaster-intro h2 {
        font-size: 2rem;
        text-align: center;
    }

    .blockmaster-intro p {
        font-size: 1.7rem;

    }

    .blockmaster-intro h3 {
        font-size: 2.1rem;
        font-family: 'Titillium Web', sans-serif;


    }

    #blockmaster-profile-text-wrapper {
        display: flex;
        flex-direction: column;

    }

    .blockmaster-profile-wrapper h2 {
        font-size: 2rem;
    }

    .blockmaster-profile-wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        #justify-content: center;

        margin: 0;
    }

    .blockmaster-img-wrapper {
        display: none;
    }

    .blockmaster-img-wrapper {
        display: flex;
        flex-direction: column;
    }

    .blockmaster-img-wrapper img {
        width: 20rem;
        height: 20rem;
    }

    .blockmaster-mobile-only {
        display: unset;
    }

    #blockmaster-features-root {
        display: flex;
        flex-direction: column;
        #justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .blockmaster-feature {
        height: auto;
        display: flex;
        align-items: center;
        padding: 1rem;
        #justify-content: center;
    }

    .blockmaster-feature:nth-child(1) {
        background-color: #440000;
    }

    .blockmaster-feature:nth-child(2) {
        background-color: #334400;
    }

    .blockmaster-feature:nth-child(odd),
    .blockmaster-feature:nth-child(even) {
        flex-direction: column;
    }

    .blockmaster-feature-inner-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 1rem;

    }

    .blockmaster-feature h1 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 2.5rem;
        margin: 0 0.5rem;
        color: white;
    }

    .blockmaster-feature img {
        height: 18rem;
        margin: 1.5rem 1rem;
    }

    .blockmaster-feature p {
        font-family: 'Titillium Web', sans-serif;
        font-size: 1.6rem;
        text-align: center;
        line-height: 1.7;
        color: white;
        margin: 1.5rem 0.2rem;
    }

    .blockmaster-description-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 0;
        padding: 5rem 2rem;
    }

    .blockmaster-description-wrapper h3 {
        font-size: 3rem;
        margin: 1.5rem 0;
        line-height: 4rem;
    }

    .blockmaster-description-text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 0;

    }

    .blockmaster-description-wrapper img {
        width: 100%;
        height: auto;
        margin: 0 0;
    }

    .blockmaster-updates-text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2rem 0;

    }

    .blockmaster-updates-text-wrapper-h3 {

        margin-bottom: 3rem;

    }


    .blockmaster-updates-wrapper {
        display: flex;
        flex-direction: column-reverse;
        color: black;
        align-items: center;
        padding: 4rem 0;

    }

    .blockmaster-updates-wrapper-image {
        width: 70%;

    }

    .blockmaster-update-block-title {
        display: flex;
        flex-direction: column;
        gap: 0;
        margin: 2rem 0;
    }

    .blockmaster-update-block-pinned, .blockmaster-update-block {
        border-radius: 0;
        margin: 0;
        padding: 0 0;
        background: 0;
    }

    .blockmaster-update-block-title-img {
        width: 30%;
    }

    .blockmaster-updates-text-wrapper h2 {
        font-size: 3rem;
        padding: 0 2rem;

    }

    .blockmaster-update-block-title-stamp {
        margin: 0 1rem 1rem;

    }

    .blockmaster-update-block-contents p {
        margin: 0;
        padding: 0 1.5rem;
    }

    .blockmaster-update-image {
        width: 100%;
        margin: 1rem 0;
        border: 0;
    }

    .blockmaster-update-block-contents-example-prompt {
        font-size: 1.5rem;
        color: white;
        border-radius: 0;
        margin: 0;
        padding: 2rem 1.5rem;
        text-align: left;
        line-height: 1.5;
    }

    .blockmaster-update-block-contents-example-tuned-and-untuned-wrapper {
        flex-wrap: wrap;
    }

    .blockmaster-update-block-contents-example-untuned, .blockmaster-update-block-contents-example-tuned {
        font-size: 1.4rem;
        text-align: left;
        margin: 0;
    }


    .blockmaster-update-block-contents-example-verdict p {
        font-size: 1.5rem;
        text-align: left;
        font-family: 'Titillium Web', sans-serif;
        padding: 2rem;


    }

    .blockmaster-update-block-contents p {
        text-align: left;
        margin: 1rem 0;
    }

    .blockmaster-update-image {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    .blockmaster-update-block-contents-example-verdict {
        padding: 0;
        margin: 0;
        background: black;

    }

    .blockmaster-developer-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #000033;
        color: white;
        padding: 0 0;
    }

    .blockmaster-developer-text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0;

    }


    .blockmaster-developer-text-wrapper p {
        font-size: 1.4rem;
        margin: 1rem 1rem 2rem;
        padding: 0;

    }


    .blockmaster-developer-wrapper img {
        width: 80%;
        border-radius: 2rem;
        margin-bottom: 4rem;

    }

    .blockmaster-developer-wrapper h3 {
        font-size: 3rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    #blockmaster-footer {
        width: 100%;
        padding: 1rem 0;
        height: 1.3rem;
        display: flex;
        font-size: 1rem;
        flex-direction: row;
        font-family: 'Segoe UI', sans-serif;
        #justify-content: space-around;
    }

    /* ====== */

    .project-description-container .title-wrapper {
        flex-direction: column;
    }

    .project-description-container .title-wrapper img {
        width:60%;
        height:60%;

    }

    .project-description-container .content-wrapper img {
        width:100%;
        border-radius:1rem;
    }



}